import React, { useState } from "react";
import { PolarisProvider } from "../../../components";
import {
  SETTLEMENT_FILTERS,
  SettlementSearch, 
  SettlementFilter
} from "../../../layout/merchant";
import Scene from "../../../layout/merchant/settlements/Scene";
import * as api from '../../../layout/merchant/utils/api'
import { logger } from "../../../core";

export default () => {
  const [selectedFilter, setSelectedFilter] = useState(SETTLEMENT_FILTERS.ALL);
  const [selectedDates, setSelectedDates] = useState({
    start: null,
    end: null,
  });
  const [resultsPerPage, setResultsPerPage]= useState(25);
  const [pageToken, setPageToken] =useState()
  const dataDownload= async()=>{
    const { response, error, success } = await api.dailyStatements({
      filterType: selectedFilter,
      fromDate:selectedDates?.start,
      toDate: selectedDates?.end,
      exportType: 'CSV',
      maxRows: Number(resultsPerPage),
      nextToken: pageToken,

    });

    if (!success) {
      logger.error("Error while fetching transactions", error);

      return;
    }
    const url = URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download',   
 'daily_statement_summary.csv');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link); 
  }
 
  return (
    <PolarisProvider>
      <Scene statementsTabs statementsHeading='Statements' dashboardTitle='Daily Settlement Summary' exportData={dataDownload}>
        <Scene.FullWidth>
          <SettlementFilter
            selectedFilter={selectedFilter}
            fromDate={selectedDates?.start}
            toDate={selectedDates?.end}
            onFilterChange={(val) => setSelectedFilter(val)}
            onSelectedDatesChange={(val) => setSelectedDates(val)}
          />
        </Scene.FullWidth>

        <Scene.FullWidth>
            <SettlementSearch             
              selectedFilter={selectedFilter}
              fromDate={selectedDates?.start}
              toDate={selectedDates?.end}
              resultsPerPageCSV={(val=>setResultsPerPage(val))}
              nextTokenCSV={(val)=> setPageToken(val)}
            />
        </Scene.FullWidth>

      </Scene>
      </PolarisProvider>
  );
};
